// eslint-disable-next-line no-var
export var Template = (window.Template = (function () {
   let cachedTemplates = {};

   function getCompiledTemplate(str) {
      let template = Handlebars.compile(str);
      return function (data, asString) {
         let html = template(data);
         return asString ? html : htmlToDom(html);
      };
   }

   /**
    * Converts a string of HTML to a DOM element.
    *
    * Returns:
    *   - One element if passed something like "<div>...</div>"
    *   - Array of elements if passed "<a>...</a><a>...</a>"
    */
   function htmlToDom(html) {
      let children;
      children = $.parseHTML ? $.parseHTML(html) : new Element('div', { html: html }).getChildren();

      // If there is only one child, return that element instead of the
      // array.
      return children.length === 1 ? children[0] : children;
   }

   // Public interface
   return {
      /**
       * Returns a function(data, asString) that takes in an object and
       * produces some DOM elements (or a string if asString is set).
       */
      get: function (str) {
         if (!cachedTemplates[str]) {
            cachedTemplates[str] = getCompiledTemplate(str);
         }

         return cachedTemplates[str];
      },
   };
})());
